var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.desktopApp)?_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('enviro_create.adver'))+" ")]):_c('v-card-text',{staticStyle:{"padding":"0px"}},[_c('MutationForm',{attrs:{"no-reset":"","mutation":"createEnvironmentUser","constants":{ environmentId: _vm.environmentId },"submitButtonText":_vm.$t('enviro_create.submitButtonText'),"submitButtonIcon":"add","submit-button-color":_vm.accentColor,"environment-variables":{ environmentId: _vm.environmentId },"schema":{
        environmentId: {
          __graphQLType: 'ID'
        },
        email: {
          label: _vm.$t('enviro_create.schema.email.label'),
          type: 'string',
          description: _vm.$t('enviro_create.schema.email.description'),
          fieldOptions: {
            color: _vm.accentColor
          },
          newDesign: true
        }
      }},on:{"success":function($event){return _vm.$emit('create', $event)},"error":function($event){return _vm.$emit('error', $event)}}},[_c('v-btn',{staticStyle:{"border":"2px solid var(--v-primary-base)"},attrs:{"slot":"left-buttons","outlined":""},on:{"click":function($event){return _vm.$emit('dismiss')}},slot:"left-buttons"},[_vm._v(_vm._s(_vm.$t('enviro_create.cancelBtn')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }